<style scoped src="./index.less" lang="less" rel="stylesheet/less"></style>
<template src="./index.html"></template>
<script>
import mixinPage from "../../libs/mixinPage";
export default {
  mixins: [mixinPage],
  components: {},
  name: "index",
  data() {
    return {
      pageName: this.$options.name,
      xtysData: [
        {
          icon: "icon11",
          t1: "丰富的营销工具",
          t2:
            "移动互联网时代快速消费成为新的中心，谁能引发冲动消费、互动消费并深度锁住顾客，谁的生意就有主动权。浩新营销以顾客经营为中心的O2O营销系统，帮助商家深层面做好顾客经营，打造新的核心竞争力，引导消费者的消费去向。",
          img: "img2"
        },
        {
          icon: "icon12",
          t1: "齐全的线上系统",
          t2:
            "我们提供的线上模块包括了桌面页面应用、安卓及IOSAPP、公众号、小程序等，以方便商家按不同的场景选择使用，线上支付采用了第三方组合通道的解决方案，稳定可靠、费率灵活。",
          img: "img10"
        },
        
        {
          icon: "icon14",
          t1: "专业的大数据分析服务",
          t2:
            "任何人都可以有自己的观点，但只有数据和事实才最有说服力。我们提供的大数据分析包括了建店设计期间的需求分析、地段客户类型消费分析、菜品结构分析等等，实现精准营销。",
          img: "img12"
        },
        {
          icon: "icon15",
          t1: "创新开发语音AI功能",
          t2:
            "为了方便企业老板及管理人员随时随地轻松掌握运营数据，我们创新开发语音AI查询功能，只需说出你想查询的数据（如营业额、预订状况），小程序马上展现出结果，突破了传统的逐层菜单操作。",
          img: "img3"
        },
        {
          icon: "icon13",
          t1: "提供专业智能化应用",
          t2:
            "采用目前最先进的3D室内无轨导航技术，再结合丰富的行业的应用，打造出专业、人性化的智能餐车。提供给中高端餐厅或中高端娱乐场所使用，主要代替传菜员、传酒员、收碗碟人员的重复劳动。结合浩新餐饮厨控管理系统，实现出品流程的智能化管理及标准化管理，以完善的系统提升机器的智能化。与管理公司或同行业公司深度合作，打造标准化管理，提升企业的竞争力。",
          img: "img11"
        },
        {
          icon: "icon16",
          t1: "提供主动、贴心的售后服务",
          t2:
            "作为领先的数字化整体解决方案提供商，浩新深知服务才是销售的开始，所以我们建立了遍布全国的畅通营销渠道，在全国拥有三十余家合作渠道商。我们建立了完善的服务登记系体，能够完美主动追踪客户的服务，并且通过系统检测到服务是否到位，做到了主动、贴心地给商家提供高质量的服务。",
          img: "img13"
        }
      ],
      khList:[
        { url:require('@/images/kh/1.jpg'), t1:"耀华饮食集团", t2:"旗下 18 家店",},
        { url:require('@/images/kh/2.jpg'), t1:"华苑大酒店", t2:"旗下 3 家店",},
        { url:require('@/images/kh/3.jpg'), t1:"六合家宴", t2:"旗下 3 间",},
        { url:require('@/images/kh/4.jpg'), t1:"潮濠酒家", t2:"",},
        { url:require('@/images/kh/5.jpg'), t1:"爱丁堡酒家", t2:"",},
        { url:require('@/images/kh/6.jpg'), t1:"红树林酒家", t2:"",},
        { url:require('@/images/kh/7.jpg'), t1:"好吉祥大酒楼", t2:"",},
        { url:require('@/images/kh/8.jpg'), t1:"鸿运达酒店", t2:"",},
        { url:require('@/images/kh/9.jpg'), t1:"同发大酒家", t2:"",},
      ],
    };
  },
  methods: {},
  mounted() {

  },
  activated() {

    if (this.$route.query.t) {
      let _id = "#t-" + this.$route.name + "-" + this.$route.query.t;
      console.log(_id);

      setTimeout(function() {
        document.querySelector(_id).scrollIntoView(true);
      }, 150);
    }
  }
};
</script>



